import * as Sentry from '@sentry/nextjs';
import { NextPageContext } from 'next';
import NextErrorComponent, { ErrorProps } from 'next/error';
import React from 'react';

import styles from './_error.module.css';

type SentryErrorProps = ErrorProps & {
  hasGetInitialPropsRun?: boolean;
  err?: unknown;
};

const ErrorPage = ({
  statusCode,
  hasGetInitialPropsRun,
  err,
}: SentryErrorProps) => {
  if (!hasGetInitialPropsRun && err) {
    Sentry.captureException(err);
  }

  return (
    <div className={styles.wrapper}>
      <NextErrorComponent statusCode={statusCode} />
    </div>
  );
};

ErrorPage.getInitialProps = async (context: NextPageContext) => {
  const errorInitialProps = (await NextErrorComponent.getInitialProps(
    context,
  )) as unknown as SentryErrorProps;

  const { res, err, asPath } = context;

  errorInitialProps.hasGetInitialPropsRun = true;

  if (res?.statusCode === 404) {
    return errorInitialProps;
  }

  if (err) {
    Sentry.captureException(err);
    await Sentry.flush(2000);

    return errorInitialProps;
  }

  Sentry.captureException(
    new Error(`_error.js getInitialProps missing data at path: ${asPath}`),
  );
  await Sentry.flush(2000);

  return errorInitialProps;
};

export default ErrorPage;
